<template>
  <div class="text-white mt-8">
    <div class="container mx-auto text-center">
      <h2 class="px-8 text-2xl font-semibold">Select a Show</h2>

      <div v-for="show in shows" :key="show.slug" class="flex flex-col justify-center items-center">
        <router-link :to="{ name: 'show', params: { slug: show.slug } }" class="mt-4 bg-white hover:bg-pink-100 shadow rounded-md p-4 w-3/5 text-pink-900 font-semibold">{{ show.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ALL_SHOWS from '@/shows.js'

export default {
  name: 'Home',

  data() {
    return {
      shows: ALL_SHOWS,
    }
  },
}
</script>
