const shows = [
    {
        name: 'Pippin, 2022 (Karaoke Orchestrations)',
        slug: 'pippin-2022',
    },
    {
        name: 'Pippin, 2022 (Piano only)',
        slug: 'pippin-2022-piano-only',
    }
]

export default shows